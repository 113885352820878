<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 ml-5 flex items-center justify-between px-6">
      <h2>{{ Object.entries(data).length === 0 ? "Add New" : "Edit" }} Early Learning Framework Category</h2>
      <feather-icon icon="XIcon" class="cursor-pointer" @click.stop="isSidebarActiveLocal = false"></feather-icon>
      <!--      @click.stop="isSidebarActiveLocal = false"-->
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <label class="block mt-5">Select Country</label>
          <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter" class="mb-4 md:mb-0" v-validate="'required'"/>
          <span class="text-danger text-sm">{{ errors.first("Country") }}</span>


        <label class="block mt-5">Select State</label>
          <v-select
            :options="stateOptions"
            :clearable="false"
            @input="onStateChange"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="stateFilter"
            class="mb-4 md:mb-0"
            v-validate="'required'"/>
          <span class="text-danger text-sm">{{ errors.first("State") }}</span>


        <!-- NAME -->
        <label class="block mt-5">Title</label>
          <vs-input v-model="title" class="w-full" name="name" v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <!--      -->
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import {mapActions} from "vuex";

export default {
  components: {VuePerfectScrollbar, vSelect},
  data() {
    return {
      countryOptions: [],
      stateOptions: [],
      countryFilter: {label: "All", value: "", id: ""},
      stateFilter: { label: "All", value: "all" , id: ""},
      dataId: null,
      title: "",
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  props: ['data', 'isSidebarActive'],
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },
  methods: {
    ...mapActions("country", [
      "getAllCountries",
    ]),
    ...mapActions("eylfCategory", [
      "storeEYLFCategory",
      "updateEYLFCategory"
    ]),
    onStateChange() {
      this.errors.clear();
    },
    async submitData() {
     await this.$validator.validateAll().then(async result => {
       if (result) {
        if (this.stateFilter.value == "") {
            this.errors.add({
                  field: "State",
                  msg: "State cant be 'All'"
            });
          return;
          }
          this.$vs.loading();
          const data = {
            country: this.countryFilter.value,
            countryId: this.countryFilter.id,
            state: this.stateFilter.value,
            stateId: this.stateFilter._id,
            title: this.title,
          }
          if (!this.dataId) {
            await this.storeEYLFCategory(data).then((res) => {
              this.errors.clear();
              this.$vs.loading.close();
              this.showMessage('Success', 'EYLF Category added successfully.', 'success');
              this.$emit('refreshData');
              this.$emit('closeSidebar');

            }).catch((err) => {
              this.$vs.loading.close();
              this.showMessage('Error', 'EYLF Category failed to add.', 'danger');

              console.log(err);
            });
          } else {
            let obj = {
              id: this.dataId,
              data: data,
            }
            this.updateEYLFCategory(obj).then((res) => {
              this.$vs.loading.close();
              this.showMessage('Success', 'EYLF Category updated successfully.', 'success');
            })
          }

          this.$emit('refreshData');
          this.$emit('closeSidebar');

        }
      })
    },
    async getCountryList() {
      await this.getAllCountries()
        .then((res) => {
          this.countryOptions = this.$store.state.country.countryOptions;
        })
        .catch(err => {
          console.error(err)
        })
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
  },
  mounted() {
    this.getCountryList();
    if (this.data) {
      this.dataId = this.data._id;
      this.title = this.data.title;
      if (this.data.country) {
        this.countryFilter = {
          label: this.data.country,
          value: this.data.country,
        };
        this.stateFilter = {
          label: this.data.state,
          value: this.data.state
        }
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.countryFilter.value != "all" &&
        this.stateFilter.value != "all" &&
        this.title
      );
    }
  }
}
</script>
