<template>
  <div id="page-user-list">
    <eylf-category-form
      v-if="createForm === true"
      :isSidebarActive="createForm"
      @closeSidebar="closeForm"
      :data="selectedCategory"
      @refreshData="refreshData"
    >
    </eylf-category-form>
    <div>
      <vx-card ref="filterCard" class="user-list-filters mb-8">
        <div class="flex align-items-center mb-8">
          <h2 class="mb-0">Early Learning Framework Category</h2>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input
              class="w-full search-input"
              v-model="searchQuery"
              placeholder="Search..."
            />
          </div>
          <div class="vx-col md:w-1/2 w-full flex justify-end">
            <vs-button color="primary" type="filled" @click="addCategory"
            >Add Early Learning Framework Category
            </vs-button>
          </div>
        </div>
        <br />
      </vx-card>
      <vx-card>
        <vs-table
          max-items="15"
          :pagination="categories.length > 0"
          :data="categories"
          @sort="handleSort"
        >
          <template slot="thead">
            <vs-th sort-key="country">Country</vs-th>
            <vs-th sort-key="state">State</vs-th>
            <vs-th>Title</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.country">
                {{ tr.country }}
              </vs-td>
              <vs-td :data="tr.state">
                {{ tr.state }}
              </vs-td>
              <vs-td :data="tr.title">
                {{ tr.title }}
              </vs-td>
              <vs-td :data="tr._id">
                <a href="#" @click.stop="editCategory(tr)">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </a>
                <a href="#" @click.stop="prepareDelete(tr._id)">
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import EylfCategoryForm from "./EylfCategoryForm";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: { EylfCategoryForm },
  data() {
    return {
      categories: [],
      createForm: false,
      selectedCategory: {},
      awaitingSearch: false,
      searchQuery: "",
      sortKey: "title"
    };
  },
  methods: {
    ...mapActions("eylfCategory", [
      "fetchEYLFCategory",
      "removeEylfCategoryById"
    ]),
    handleSort(key, active) {
      this.sortKey = key;
      this.getCategoryList();
    },
    refreshData() {
      this.getCategoryList();
    },
    addCategory() {
      this.selectedCategory = "";
      this.createForm = true;
    },
    closeForm() {
      this.createForm = false;
    },
    async getCategoryList(val = "") {
      // const params = `?search=${val}`;
      const params = {
        search: val,
        sort: this.sortKey
      }
      this.$vs.loading();
      await this.fetchEYLFCategory(params)
        .then(res => {
          this.$vs.loading.close();
          this.categories = res.data.data;
        })
        .catch(err => {
          this.$vs.loading.close();

          console.log(err);
        });
    },
    editCategory(data) {
      this.selectedCategory = data;
      this.createForm = true;
    },
    prepareDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.delete(id);
        }
      });
    },
    async delete(id) {
      this.$vs.loading();

      this.removeEylfCategoryById(id)
        .then(res => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "EYLF category deleted successfully.",
            "success"
          );
          this.getCategoryList();
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage(
            "Failure",
            "EYLF category can not be deleted.",
            "danger"
          );
        });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    }
  },
  mounted() {
    this.getCategoryList("");
  },
  watch: {
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getCategoryList(val);
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  }
};
</script>
